
import { defineComponent, onMounted } from "vue";
import PaymentsTable from "@/components/admin/finance/PaymentsTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "admin-payments-listing",
  components: {
    PaymentsTable,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Payments List", ["Finance"]);
    });
  },
  created() {
    //Set page title
    document.title = "Payments List | " + process.env.VUE_APP_TITLE;
  },
});
